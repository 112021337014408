import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import { SiteLayout, Head } from '~/components';
import * as contact from '~/pages/contact/contact.module.scss';
import { IconArrowRight } from '~/icons';

const ContactPage = ({ location, data }) => {
  const siteTitle = data.site.siteMetadata.title;

  useEffect(() => {
    if (location.state?.process !== 'thanks') navigate('/contact/');
  });

  return (
    <SiteLayout title={siteTitle}>
      <Head title="お問い合わせ (送信完了)" />

      <div className={contact.content}>

        <div className={contact.hero}>
          <h1 className={contact.title}>お問い合わせを受け付けました。</h1>
          <p className={contact.message}>休業日・祝日の前後はご回答が遅れる場合がございますのであらかじめご了承ください。<br />
        フォームからお問い合わせ頂きましたら折り返しご連絡いたします。</p>
          <div className={contact.flow}>
            <div className={contact.dot} />
            <div className={contact.dot} />
            <div className={contact.activeDot} />
          </div>
        </div>

        <dl className={contact.def}>
          <dt className={contact.defTitle}>■ お急ぎの場合はお電話にて問い合わせください。担当者が不在の場合は折り返しのご連絡となります。</dt>
          <dd className={contact.defText}>Tel <a className={contact.tel} href="tel:0242-23-8285">0242-23-8285</a> (平日 9:30~17:30)</dd>
        </dl>

        <dl className={contact.def}>
          <dt className={contact.defTitle}>■ 採用についてはこちらをご確認ください。</dt>
          <dd className={contact.defText}>
            <a
              href="https://www.green-japan.com/company/7196"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-slim"><span>採用情報はこちら</span><IconArrowRight /></a>
          </dd>
        </dl>

      </div>

    </SiteLayout>
  )
}

export default ContactPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
