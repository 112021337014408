// extracted by mini-css-extract-plugin
export var activeDot = "contact__activeDot-dNN-r";
export var backButton = "contact__backButton-hkr2T";
export var button = "contact__button-5UsU6";
export var content = "contact__content-0L7QH";
export var controllbar = "contact__controllbar-wME4f";
export var def = "contact__def-aXz8j";
export var defText = "contact__defText-nMB3v";
export var defTitle = "contact__defTitle-iX42-";
export var dot = "contact__dot-pbgNZ";
export var error = "contact__error-oVWL9";
export var field = "contact__field-iPzRJ";
export var fieldPre = "contact__fieldPre-jVLOC";
export var fieldText = "contact__fieldText-VpboZ";
export var flow = "contact__flow-pq98W";
export var hero = "contact__hero-W5gBU";
export var icon = "contact__icon-ZghSu";
export var inner = "contact__inner-wTisf";
export var isError = "contact__isError-Kp9pK";
export var message = "contact__message-bqi+3";
export var tel = "contact__tel-iN0od";
export var termsCheck = "contact__termsCheck-kyq6Y";
export var termsText = "contact__termsText-KCiCW";
export var title = "contact__title-fFBAq";